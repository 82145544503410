<template>
  <v-container fluid class="upsell-expansion pa-2">
    <v-row v-if="exclusionary" class="overflow-x-auto pb-5">
      <Card key="1" :items="items" :exclusionary="exclusionary" />
    </v-row>
    <v-row v-else class="overflow-x-auto pb-5">
      <Card
        v-for="i in 4"
        :key="i"
        :position="i"
        :items="itemsForPosition(i)"
        :exclusionary="exclusionary"
      />
    </v-row>
  </v-container>
</template>

<script>
import Card from "components/upsell/upsell_item_expansion_card.vue";

export default {
    data() {
        return {};
    },
    components: {
        Card,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        exclusionary: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        itemsForPosition(position) {
            return this.items.filter((el) => el.Position == position);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~css/vuebar.css";

.upsell-expansion {
  background-color: #f9f9f9;
}
</style>
