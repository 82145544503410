var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "upsell-expansion-card mt-4 mb-4 ml-4",
      attrs: { elevation: "1" },
    },
    [
      _vm.position
        ? _c("v-card-title", { staticClass: "pb-0" }, [
            _vm._v(_vm._s(`Position ${_vm.position}:`)),
          ])
        : _vm._e(),
      _vm.items.length
        ? _c(
            "v-list",
            { attrs: { "hide-actions": "" } },
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-list-item",
                { key: item.title, staticClass: "upsell-list-item" },
                [
                  _vm.exclusionary
                    ? _c("v-list-item-action", [
                        _c("div", { staticClass: "upsell-exclude" }),
                      ])
                    : _c("v-list-item-action", [
                        _c("div", { staticClass: "upsell-logo" }),
                      ]),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(
                            `${item.inventoryItemName} (${item.InventoryItemID}) ${item.costDisplay}`
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("v-card-text", [_vm._v("No items assigned.")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }