var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: _vm.title },
      }),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-banner",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { slot: "icon", color: "warning", size: "36" },
                          slot: "icon",
                        },
                        [_vm._v(" mdi-alert-outline")]
                      ),
                      _vm._v(" " + _vm._s(_vm.hintText) + " "),
                    ],
                    1
                  ),
                  _vm.canEdit
                    ? _c("v-row", { staticClass: "action-row mt-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "action-container",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onAdd.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upsell-logo" }),
                            _c("div", { staticClass: "action-row__title" }, [
                              _vm._v(_vm._s(_vm.addText)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "action-container",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onAddExclusionary.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upsell-exclude" }),
                            _c(
                              "div",
                              { staticClass: "action-row__exclusionary_title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.addExlcusionaryText) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.upsellAddonItems.length
                    ? _c("v-data-table", {
                        staticClass: "upsell_item_table",
                        attrs: {
                          items: _vm.upsellAddonItems,
                          headers: _vm.headers,
                          "item-key": "ID",
                          "hide-default-header": "",
                          "hide-default-footer": "",
                          options: _vm.tableOptions,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-data",
                              fn: function () {
                                return [_c("div", { attrs: { id: "no-data" } })]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function ({ expand, item, isExpanded }) {
                                return [
                                  _c(
                                    "tr",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return expand(!isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "item-row justify-start align-center",
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "row__icon",
                                              class: {
                                                "row__icon--active":
                                                  !isExpanded,
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "row__title" },
                                              [_vm._v(_vm._s(item.display))]
                                            ),
                                            isExpanded && _vm.canEdit
                                              ? _c("div", {
                                                  staticClass: "row--edit",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onEditItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            isExpanded && _vm.canEdit
                                              ? _c("div", {
                                                  staticClass: "row--delete",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onDeleteItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "expanded-item",
                              fn: function ({ item }) {
                                return [
                                  _c("UpsellItemExpansion", {
                                    attrs: {
                                      items: item.items,
                                      exclusionary: item.exclusionary,
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          335421950
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.upsellSelectionVisible
        ? _c(
            "EditingModal",
            { staticClass: "modal" },
            [
              _c("UpsellSelectionCard", {
                attrs: {
                  title: _vm.exclusionary
                    ? _vm.addExclusionaryModalTitle
                    : _vm.addModalTitle,
                  existingTargetSet: _vm.existingTargetSet,
                  hideStore: _vm.exclusionary,
                  exclusionary: _vm.exclusionary,
                },
                on: {
                  close: _vm.closeModal,
                  selected: _vm.handleTargetSelection,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteConfirmationModalID
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModalID = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "Are you sure you want to delete all upsell items associated with this item?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }