// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!css/vuebar.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/folder_open_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".upsell-target-selection-card .v-data-table tbody tr[data-v-d0877ffe]:hover:not(.v-data-table__expand-row) {\n  background: rgba(98, 197, 241, 0.2);\n}\n.upsell-target-selection-card .v-data-table tbody tr[active][data-v-d0877ffe] {\n  background: rgba(98, 197, 241, 0.4);\n}\n.upsell-target-selection-card .v-data-table tbody tr td[data-v-d0877ffe] {\n  color: #585858 !important;\n  vertical-align: middle;\n  height: 41px;\n  font-size: 14px;\n  padding-left: 30px;\n  width: 250px;\n}\n.upsell-target-selection-card .upsell-target-selection-row[data-v-d0877ffe] {\n  color: #585858 !important;\n  font-size: 14px;\n  vertical-align: middle;\n}\n.upsell-target-selection-card .group-title[data-v-d0877ffe] {\n  padding-left: 20px;\n}\n.upsell-target-selection-card .item-title[data-v-d0877ffe] {\n  padding-left: 50px;\n}\n.upsell-target-selection-card .group-icon[data-v-d0877ffe] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 18px;\n  height: 18px;\n}", ""]);
// Exports
module.exports = exports;
