var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "upsell-expansion pa-2", attrs: { fluid: "" } },
    [
      _vm.exclusionary
        ? _c(
            "v-row",
            { staticClass: "overflow-x-auto pb-5" },
            [
              _c("Card", {
                key: "1",
                attrs: { items: _vm.items, exclusionary: _vm.exclusionary },
              }),
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "overflow-x-auto pb-5" },
            _vm._l(4, function (i) {
              return _c("Card", {
                key: i,
                attrs: {
                  position: i,
                  items: _vm.itemsForPosition(i),
                  exclusionary: _vm.exclusionary,
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }