<template>
  <v-card class="upsell-expansion-card mt-4 mb-4 ml-4" elevation="1">
    <v-card-title v-if="position" class="pb-0">{{
      `Position ${position}:`
    }}</v-card-title>
    <v-list v-if="items.length" hide-actions>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        class="upsell-list-item"
      >
        <v-list-item-action v-if="exclusionary">
          <div class="upsell-exclude"></div>
        </v-list-item-action>
        <v-list-item-action v-else>
          <div class="upsell-logo"></div>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{
            `${item.inventoryItemName} (${item.InventoryItemID}) ${item.costDisplay}`
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text v-else>No items assigned.</v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        position: {
            type: Number,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        exclusionary: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~css/vuebar.css";

// .upsell-expansion-card {
//     height: 250px;
// }
.v-card {
  &__title {
    color: #585858 !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
  }

  &__text {
    color: #585858 !important;
    font-size: 14px !important;
  }
}

.v-list {
  &__tile__title {
    color: #585858 !important;
    font-size: 14px;
  }
  &__tile__action {
    min-width: 35px;
  }
}
.v-list-item {
  &__content {
    font-size: 14px !important;
  }
  &__title {
    font-size: 14px !important;
  }
}

.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");

  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
.upsell-exclude {
  @include background-image("", "upsell_exclude.svg", "../../img/");

  flex-grow: 0 !important;
  width: spacing(md);
  height: spacing(md);
  background-size: contain;
}
.upsell-list-item {
  height: 12px;
}
.v-list-item {
  min-height: 38px;
}
.v-application--is-ltr .v-list-item__action:first-child {
  margin-right: 10px;
}
</style>
