<template>
  <v-card class="upsell-target-selection-card">
    <v-card-title class="pa-0">
      <v-toolbar>
        <v-toolbar-title> {{ title }} </v-toolbar-title>
        <v-spacer />
        <v-btn color="blue-grey" class="ma-2 white--text" @click="onCancel">
          Cancel
          <v-icon right dark> mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        v-bar
        :items="items"
        :headers="headers"
        disable-pagination
        hide-default-header
        hide-default-footer
      >
        <template v-slot:item="props">
          <tr class="upsell-selection-row" @click="onClick(props.item)">
            <td v-if="props.item.type == 'store'">
              <v-row class="pt-1">
                <div class="group-icon"></div>
                <div class="group-title">{{ props.item.title }}</div>
              </v-row>
            </td>
            <td v-else-if="props.item.type == 'category'">
              <v-row class="pt-1">
                <div class="group-icon"></div>
                <div class="group-title">{{ props.item.title }}</div>
              </v-row>
            </td>
            <td v-else>
              <div class="item-title">{{ props.item.title }}</div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContextTitle from "components/context_title.vue";

export default {
    data() {
        return {
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name",
                },
                {
                    sortable: false,
                    text: "",
                    value: "state",
                },
            ],
        };
    },
    props: {
        existingTargetSet: {
            type: Set,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        hideStore: {
            type: Boolean,
            required: true,
        },
        exclusionary: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ContextTitle,
    },
    computed: {
        items() {
            if (
                this.productCategories == undefined ||
        this.productCategories.length == 0 ||
        this.existingTargetSet == undefined
            )
                return [];
            var items = [];

            if (
                !this.existingTargetSet.has(
                    `${this.store.storeWaypointID}${this.exclusionary}`
                ) &&
        !this.hideStore
            ) {
                items.push({
                    id: this.store.storeWaypointID,
                    type: "store",
                    title: `Store - ${this.store.storeName}`,
                    canSelect: true,
                });
            }

            for (let category of this.productCategories) {
                if (category.inventoryTitleDescription == "SHOW ALL") continue;
                if (
                    !this.existingTargetSet.has(
                        `${category.inventoryTitleID}${this.exclusionary}`
                    )
                )
                    items.push({
                        id: category.inventoryTitleID,
                        type: "category",
                        title: category.inventoryTitleDescription,
                        canSelect: !this.existingTargetSet.has(
                            `${category.inventoryTitleID}${this.exclusionary}`
                        ),
                    });

                for (let item of category.children) {
                    if (
                        !this.existingTargetSet.has(
                            `${item.inventoryItemID}${this.exclusionary}`
                        )
                    )
                        items.push({
                            id: item.id,
                            type: "item",
                            title: item.inventoryItemName,
                            canSelect: !this.existingTargetSet.has(
                                `${item.inventoryItemID}${this.exclusionary}`
                            ),
                        });
                }
            }

            return items;
        },
        ...mapGetters(["productCategories", "store"]),
    },
    methods: {
        onCancel() {
            this.$emit("close");
        },
        onClick(item) {
            if (!item.canSelect) return;
            this.$emit("selected", item);
        },
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
@import "~css/vuebar.css";

.upsell-target-selection-card {
  .v-data-table {
    tbody {
      tr:hover:not(.v-data-table__expand-row) {
        background: rgba(98, 197, 241, 0.2);
      }
      tr[active] {
        background: rgba(98, 197, 241, 0.4);
      }
      tr {
        td {
          color: #585858 !important;
          vertical-align: middle;
          height: 41px;
          font-size: 14px;
          padding-left: 30px;
          width: 250px;
        }
      }
    }
  }

  .upsell-target-selection-row {
    color: #585858 !important;
    font-size: 14px;
    vertical-align: middle;
  }
  .group-title {
    padding-left: 20px;
  }
  .item-title {
    padding-left: 50px;
  }
  .group-icon {
    @include background-image("", "folder_open_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
  }
}
</style>
