var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "upsell-target-selection-card" },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c(
            "v-toolbar",
            [
              _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2 white--text",
                  attrs: { color: "blue-grey" },
                  on: { click: _vm.onCancel },
                },
                [
                  _vm._v(" Cancel "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c("v-data-table", {
            directives: [{ name: "bar", rawName: "v-bar" }],
            attrs: {
              items: _vm.items,
              headers: _vm.headers,
              "disable-pagination": "",
              "hide-default-header": "",
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (props) {
                  return [
                    _c(
                      "tr",
                      {
                        staticClass: "upsell-selection-row",
                        on: {
                          click: function ($event) {
                            return _vm.onClick(props.item)
                          },
                        },
                      },
                      [
                        props.item.type == "store"
                          ? _c(
                              "td",
                              [
                                _c("v-row", { staticClass: "pt-1" }, [
                                  _c("div", { staticClass: "group-icon" }),
                                  _c("div", { staticClass: "group-title" }, [
                                    _vm._v(_vm._s(props.item.title)),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : props.item.type == "category"
                          ? _c(
                              "td",
                              [
                                _c("v-row", { staticClass: "pt-1" }, [
                                  _c("div", { staticClass: "group-icon" }),
                                  _c("div", { staticClass: "group-title" }, [
                                    _vm._v(_vm._s(props.item.title)),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _c("td", [
                              _c("div", { staticClass: "item-title" }, [
                                _vm._v(_vm._s(props.item.title)),
                              ]),
                            ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }