<template>
  <div>
    <ContextTitle :passedActions="titleBarActions" :title="title" />
    <v-container class="pa-0" fluid>
      <v-row class="pa-0">
        <v-col md="12">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-alert-outline</v-icon
            >
            {{ hintText }}
          </v-banner>
          <v-row v-if="canEdit" class="action-row mt-0">
            <div class="action-container" @click.stop="onAdd">
              <div class="upsell-logo" />
              <div class="action-row__title">{{ addText }}</div>
            </div>
            <div class="action-container" @click.stop="onAddExclusionary">
              <div class="upsell-exclude" />
              <div class="action-row__exclusionary_title">
                {{ addExlcusionaryText }}
              </div>
            </div>
          </v-row>

          <v-data-table
            v-if="upsellAddonItems.length"
            class="upsell_item_table"
            :items="upsellAddonItems"
            :headers="headers"
            item-key="ID"
            hide-default-header
            hide-default-footer
            :options="tableOptions"
          >
            <template v-slot:no-data>
              <div id="no-data" />
            </template>
            <template v-slot:item="{ expand, item, isExpanded }">
              <tr @click="expand(!isExpanded)">
                <td>
                  <div class="item-row justify-start align-center">
                    <div
                      class="row__icon"
                      :class="{ 'row__icon--active': !isExpanded }"
                    ></div>
                    <div class="row__title">{{ item.display }}</div>
                    <div
                      v-if="isExpanded && canEdit"
                      class="row--edit"
                      @click.stop="onEditItem(item)"
                    ></div>
                    <div
                      v-if="isExpanded && canEdit"
                      class="row--delete"
                      @click.stop="onDeleteItem(item)"
                    ></div>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ item }">
              <UpsellItemExpansion
                :items="item.items"
                :exclusionary="item.exclusionary"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <EditingModal v-if="upsellSelectionVisible" class="modal">
      <UpsellSelectionCard
        :title="exclusionary ? addExclusionaryModalTitle : addModalTitle"
        :existingTargetSet="existingTargetSet"
        :hideStore="exclusionary"
        :exclusionary="exclusionary"
        @close="closeModal"
        @selected="handleTargetSelection"
      />
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModalID" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => (deleteConfirmationModalID = false)"
        :actions="deleteConfirmationActions"
        >Are you sure you want to delete all upsell items associated with this
        item?</AlertDialog
      >
    </EditingModal>
  </div>
</template>


<script>
import ContextTitle from "components/context_title.vue";
import UpsellItemExpansion from "components/upsell/upsell_item_expansion.vue";
import UpsellSelectionCard from "components/upsell/upsell_target_selection_card.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import AccessMixin from "mixins/access_helper.js";

export default {
    name: "UpsellMenuItem",
    mixins: [AccessMixin],
    data() {
        return {
            deleteConfirmationModalID: false,
            deleteConfirmationActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.deleteConfirmationModalID = false;
                    },
                },
                {
                    type: "remove-red",
                    display: "Yes, delete",
                    run: this.deleteItem,
                },
            ],
            upsellSelectionVisible: false,
            headersLength: 2,
            titleBarActions: [],
            rowsPerPageItems: [10, 20, 30, 40, 100],
            pagination: {
                rowsPerPage: 100,
                page: 1,
            },
            expand: false,
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name",
                },
            ],
            exclusionary: false,
            tableOptions: {
                itemsPerPage: 100,
            },
        };
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        addText: {
            type: String,
            required: true,
        },
        addModalTitle: {
            type: String,
            required: true,
        },
        upsellAddonItems: {
            type: Array,
            required: true,
        },
        addExlcusionaryText: {
            type: String,
            required: true,
        },
        addExclusionaryModalTitle: {
            type: String,
            required: true,
        },
        hintText: {
            type: String,
            required: true,
        },
    },
    components: {
        ContextTitle,
        UpsellItemExpansion,
        UpsellSelectionCard,
        EditingModal,
        AlertDialog,
    },
    computed: {
        existingTargetSet() {
            if (this.upsellAddonItems === undefined) return new Set();
            return new Set(
                this.upsellAddonItems.map((el) => `${el.TargetID}${el.exclusionary}`)
            );
        },
    },
    methods: {
        closeModal() {
            this.upsellSelectionVisible = false;
        },
        handleTargetSelection(target) {
            this.upsellSelectionVisible = false;
            this.$emit("handleTargetSelection", {
                ...target,
                exclusionary: this.exclusionary,
            });
        },
        onEditItem(item) {
            this.$emit("onEditItem", item);
        },
        onDeleteItem(item) {
            this.deleteConfirmationModalID = item.ID;
        },
        deleteItem() {
            let targetID = this.deleteConfirmationModalID;
            this.deleteConfirmationModalID = false;
            this.$emit("onDeleteItem", targetID);
        },
        onAdd() {
            this.exclusionary = false;
            this.upsellSelectionVisible = true;
        },
        onAddExclusionary() {
            this.exclusionary = true;
            this.upsellSelectionVisible = true;
        },
    },
};
</script>
 
<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

#no-data {
  height: 100%;
  width: 100%;
}

.v-data-table {
  tbody {
    tr:hover:not(.v-datatable__expand-row) {
      background: white;
    }
    tr {
      td {
        color: #585858 !important;
        vertical-align: middle;
        height: 41px;
        font-size: 14px;
        width: 250px;
      }
    }
  }
}
.action-container {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-content: center;
  align-items: center;
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "folder_closed_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);

    &--active {
      @include background-image("", "folder_open_logo.svg", "../../img/");
    }
  }

  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.action-row {
  padding: 15px;
  cursor: pointer;
  align-items: center;
  &__title {
    margin-left: 5px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
  &__exclusionary_title {
    margin-left: 5px;
    color: red !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
}
.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");
  margin-left: 15px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
.upsell-exclude {
  @include background-image("", "upsell_exclude.svg", "../../img/");

  flex-grow: 0 !important;
  width: spacing(lg);
  height: spacing(lg);
  background-size: contain;
}
.item-row {
  height: 41px;
}
</style>
